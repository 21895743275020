@import 'styles';

.disclaimer-modal {
  &-text {
    .mobile-breakpoint({
      overflow-y: scroll;
      max-height: 60vh;
    });

    h2 {
      text-align: center;
      margin-bottom: 30px;
    }

    .logo-holder {
      text-align: center;
      margin: 30px 0px;

      .mobile-breakpoint({
        margin: 10px 0;
      });
    }
  }
}

@primary-color: #f1c400;@link-color: #000;@link-hover-color: #333;@font-size-base: 14px;@font-family: Roboto, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;@layout-header-background: #e6e6e6;@layout-body-background: #fff;@layout-footer-background: #151616;