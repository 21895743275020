@import 'styles';

.site-footer {
  background-color: @layout-footer-background;
  color: @light-font-color;

  &-container {
    padding: 24px;

    .lmi-logo {
      max-width: 220px;
      padding-right: 20px;
      font-size: 12px;
      border-right: 0.5px solid @light-background;
      img {
        max-height: 60px;
      }
      .mobile-breakpoint({
        display: none;
      });
    }
  }

  &-nav-container {
    padding: 25px;
  }

  &-nav {
    margin-bottom: 0;

    &-item {
      margin-bottom: 8px;

      > a {
        color: @light-font-color;
        white-space: nowrap;

        &:hover {
          color: @light-font-color;
        }
      }

      @media screen and (min-width: 768px) {
        &:not(:last-of-type)::after {
          color: rgba(255, 255, 255, 0.25);
          content: '|';
          margin: 0 10px;
        }
      }
    }

    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  &-copyright {
    color: #999;
    margin-top: 1em;
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
      margin-top: 0;
    }
  }

  &-social-link {
    &s {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 0;
    }

    &-item {
      background-color: @primary-color;
      border-radius: 50%;
      cursor: pointer;
      height: 32px;
      width: 32px;
      text-align: center;
      line-height: 32px;
      font-size: 16px;
      margin-left: 8px;
      transition: background-color 250ms ease-out;

      &:hover {
        background-color: darken(@primary-color, 5%);
      }

      > a {
        color: @light-font-color;

        &:hover {
          color: @light-font-color;
        }
      }
    }
  }
}

@primary-color: #f1c400;@link-color: #000;@link-hover-color: #333;@font-size-base: 14px;@font-family: Roboto, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;@layout-header-background: #e6e6e6;@layout-body-background: #fff;@layout-footer-background: #151616;