.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-container {
  padding-top: 68px;
  min-height: 70vh;
  background-color: @layout-body-background;
}

@primary-color: #f1c400;@link-color: #000;@link-hover-color: #333;@font-size-base: 14px;@font-family: Roboto, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;@layout-header-background: #e6e6e6;@layout-body-background: #fff;@layout-footer-background: #151616;