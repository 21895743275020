.mobile-breakpoint(@rules) {
  @media screen and (max-width: @mobile) {
    @rules();
  }
}

.tablet-breakpoint(@rules) {
  @media screen and (max-width: @tablet) {
    @rules();
  }
}

.desktop-breakpoint(@rules) {
  @media screen and (max-width: @desktop) {
    @rules();
  }
}

.carousel-arrows(@color, @size: 40px, @margin: 0) {
  .slick-prev,
  .slick-next {
    color: @color;
    height: auto;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
    transition: 200ms color linear;
    z-index: 99;

    &:hover {
      color: darken(@color, 25%);
    }

    svg {
      width: @size;
      height: @size;
    }
  }

  .slick-prev {
    left: @margin;
  }

  .slick-next {
    right: @margin;
  }
}

.carousel-dots(@color, @size: 10px, @gutter: 4px) {
  .slick-dots {
    &.slick-dots-bottom {
      bottom: -50px;
    }

    li {
      width: auto;
      height: auto;
      margin: 0 @gutter;

      button {
        width: @size;
        height: @size;
        background: @color;
        border-radius: 100%;
      }

      &.slick-active {
        width: auto;

        button {
          background: @color;
        }
      }
    }
  }
}

.center-element() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-button-color(@color) {
  background-color: @color;
  border-color: @color;

  &:hover {
    background-color: lighten(@color, 15%);
    border-color: lighten(@color, 15%);
  }
  &:focus {
    background-color: @color;
    border-color: @color;
  }
  &:disabled {
    background-color: darken(@accent-lightgray, 10%);
    border-color: @accent-lightgray;
  }
}

.comparison-table() {
  .section {
    &-cover {
      position: relative;
      overflow: hidden;
      display: flex;
      height: 100%;
      min-height: 0;
      flex-direction: row;
      background-color: @light-background;

      &-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: @light-background;
        font-weight: 700;
        padding: 10px;
        min-width: @grid-box-width;
        max-width: @grid-box-width;
        min-height: @grid-box-height;
        border-right: 1px solid darken(@light-background, 5%);
        border-bottom: 1px solid darken(@light-background, 5%);
        z-index: 8;

        .mobile-breakpoint({
          min-width: @grid-mobile-name-box-width;
          max-width: @grid-mobile-name-box-width;
          font-size: 12px;
        });
      }

      &-contents {
        width: 100%;
        display: flex;
        position: relative;
        min-height: @grid-box-height;
        left: @grid-box-width;
        width: @grid-box-width * 8;
        transition: left 0.2s linear;
        background-color: @light-background;
        border-bottom: 1px solid darken(@light-background, 5%);

        &:hover {
          background-color: lighten(@light-background, 10%);
          height: auto;

          .content-icon {
            &.icon-text {
              display: block;
            }
          }
        }

        &-box {
          .center-element();
          min-width: @grid-box-width;
          max-width: @grid-box-width;
          border-right: 1px solid @light-background;
        }
      }
    }
  }
}

.themed-scroll-y() {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    background: darken(@light-background, 20%);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: darken(@light-background, 10%);
  }
}
