.error-boundary {
  padding: 40px;

  &-icon {
    width: 400px;
    height: 400px;
    margin: auto;
    background-image: url(./assets/oops.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@primary-color: #f1c400;@link-color: #000;@link-hover-color: #333;@font-size-base: 14px;@font-family: Roboto, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;@layout-header-background: #e6e6e6;@layout-body-background: #fff;@layout-footer-background: #151616;