@import 'styles';

.site-header-user-actions {
  display: flex;
  justify-content: center;

  .site-header-user {
    &-name {
      padding: 0px 10px;
    }
  }

  .login-btn {
    .custom-button-color(@dark-background);
    color: @light-font-color;
    border-radius: 5px;
    margin-left: 15px;
  }
}

@primary-color: #f1c400;@link-color: #000;@link-hover-color: #333;@font-size-base: 14px;@font-family: Roboto, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;@layout-header-background: #e6e6e6;@layout-body-background: #fff;@layout-footer-background: #151616;