@import 'styles/index.less';

.site-header {
  position: fixed;
  width: 100%;
  background-color: lighten(@light-background, 2%);
  border-bottom: 3px solid @primary-color;
  z-index: 999;
  transform: translateY(-100%);
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

  &.visible {
    transform: translateX(0);
  }

  &.shadow {
    box-shadow: 0px 0px 6px @accent-gray;
  }

  &-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 24px;
    height: 65px;
  }

  &-logo {
    img {
      max-width: 100%;
      .mobile-breakpoint({
        width: 80%;
      });
    }
  }

  &-actions {
    min-width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &-mobile-actions {
    background-color: lighten(@light-background, 10%);
    height: auto;
    padding: 15px;
    position: absolute;
    right: -24px;
    top: 50px;
    box-shadow: 0px 3px 10px lighten(@accent-gray, 50);
    display: none;

    &.open {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &-classes {
    li {
      list-style: none;
    }
  }
}

@primary-color: #f1c400;@link-color: #000;@link-hover-color: #333;@font-size-base: 14px;@font-family: Roboto, 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;@layout-header-background: #e6e6e6;@layout-body-background: #fff;@layout-footer-background: #151616;